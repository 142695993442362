import { theme, ThemeConfig } from 'antd'
import { theme as globalTheme } from '../components/shared/theme'
const { colors } = globalTheme

const { defaultAlgorithm } = theme

export const appTheme: ThemeConfig = {
  token: {
    colorPrimary: colors.primary,
    colorBgContainer: colors.appBackground,
    colorError: colors.timerWarning,
    colorBorder: colors.ternaryDark,
    colorIcon: colors.danger
  },

  components: {
    Layout: {
      colorBgHeader: colors.appBackground,
    },
    Radio: {
        colorPrimary: colors.black
    },
    Menu: {
      darkItemBg: colors.appBackground,
      darkItemSelectedBg: colors.primaryLight,
      darkItemColor: colors.black,
      darkItemHoverColor: colors.appPrimary,
      iconSize: 24,
      darkItemSelectedColor: colors.primary,
    },
    Button: {
      primaryColor: colors.appPrimary,
    },
  },
  algorithm: [defaultAlgorithm],
}
