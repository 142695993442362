import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { Actions } from '../utils/actionTypes'
import { getAPIEndPoints } from '../../utils/api'
import AxiosInstance from '../AxiosInstance'
import { InFlightNames, InFlightStatuses } from '../../utils/types'
import {
  RequestReportDicomUrlTokenPaylodType,
  RequestReportTemplatePayloadType,
  RequestSendBackToHilPoolPaylodType,
  RequestStudyById,
} from './reportPayloadTypes'
import { AUTH_TOKEN, OSTEOCHECK_MOD_STUDY } from '../../utils/constants'
import { RuleType } from '../cases/caseSlice'

export type PathologyType = {
  created_at: string
  id: number
  impression: string
  mod_study_fk: number
  observation: string
  other_findings_fk: number[]
  pathology: string
  status: string
  updated_at: string
  variables_fk: number[]
}

export type StudyType = {
  created_at: string
  ct_ratio: null | string
  history: string
  mod_study: number
  order_id: string
  patient_name: string
  report_header: string
  status: string
  study_id: number
  study_iuid: string
  is_ai_report?: boolean
  paediatric?: boolean
  type_id?: number
  rules: RuleType[]
}

export type ReportType = {
  urlToken: string
  osteocheck?: boolean
  studyData: StudyType
  FormData: any
}

export enum ActionTypes {
  NON_REPORTABLE = 'non_reportable',
  SKIP = 'skip',
  EDIT = 'edit',
  REPORT = 'report',
}

export interface ReportState {
  reports: ReportType
  inFlights: {
    [InFlightNames.requestGetStudyInFlight]: { status: InFlightStatuses }
    [InFlightNames.requestGetReportTemplateInFlight]: { status: InFlightStatuses }
    [InFlightNames.requestGetDicomUrlTokenInFlight]: { status: InFlightStatuses }
    [InFlightNames.requestTakeActionInFlight]: { status: InFlightStatuses }
    [InFlightNames.requestSendBackToHilPoolInFlight]: { status: InFlightStatuses }
  }
}

const initialState: ReportState = {
  reports: { urlToken: '', osteocheck: false } as ReportType,
  inFlights: {
    [InFlightNames.requestGetStudyInFlight]: { status: InFlightStatuses.INITIAL },
    [InFlightNames.requestGetReportTemplateInFlight]: { status: InFlightStatuses.INITIAL },
    [InFlightNames.requestGetDicomUrlTokenInFlight]: { status: InFlightStatuses.INITIAL },
    [InFlightNames.requestTakeActionInFlight]: { status: InFlightStatuses.INITIAL },
    [InFlightNames.requestSendBackToHilPoolInFlight]: { status: InFlightStatuses.INITIAL },
  },
  
}

export const requestGetStudy = createAsyncThunk<any, RequestStudyById>(
  Actions.requestGetStudy,
  async (data, { rejectWithValue, dispatch }) => {
    const { id, userId } = data
    try {
      const study = await AxiosInstance.get(getAPIEndPoints.reports.get_study(id, userId), {
        headers: {
          Authorization: AUTH_TOKEN,
        },
      })
      const mod_study = study?.data?.data?.[0]?.mod_study ?? -1
      if (mod_study >= 0) {
        await dispatch(requestGetReportTemplate({ modStudyId: mod_study }))
      }

      return study?.data?.data
    } catch (e) {
      console.log(e, 'error')
      return rejectWithValue({})
    }
  }
)

export const requestGetReportTemplate = createAsyncThunk<any, RequestReportTemplatePayloadType>(
  Actions.requestGetReportTemplate,
  async (data, { rejectWithValue }) => {
    const { modStudyId } = data
    try {
      const report = await AxiosInstance.get(getAPIEndPoints.reports.get_report_template(modStudyId), {
        headers: {
          Authorization: AUTH_TOKEN,
        },
      })
      return report.data
    } catch (e) {
      return rejectWithValue({})
    }
  }
)

export const requestGetDicomUrlToken = createAsyncThunk<string, RequestReportDicomUrlTokenPaylodType>(
  Actions.requestGetDicomUrlToken,
  async (data, { rejectWithValue }) => {
    const { iuid } = data
    try {
      const res = await AxiosInstance.get(getAPIEndPoints.reports.get_dicom_token(iuid), {
        headers: {
          Authorization: AUTH_TOKEN,
        },
      })
      return res.data
    } catch (e) {
      return rejectWithValue({})
    }
  }
)
export const requestSendBackToHilPool = createAsyncThunk<void, RequestSendBackToHilPoolPaylodType>(
  Actions.requestSendBackToHilPool,
  async (data, { rejectWithValue }) => {
    try {
      await AxiosInstance.put(getAPIEndPoints.reports.sendBackToPool(), {
        headers: {
          Authorization: AUTH_TOKEN,
        },
        data,
      })
    } catch (e) {
      console.log('Failed to send back to Hil Pool')
      return rejectWithValue({})
    }
  }
)

export const requestTakeAction = createAsyncThunk<any, any>(
  Actions.requestTakeAction,
  async (data, { rejectWithValue }) => {
    const { action, study_id, findings, email } = data
    try {
      const payload = {
        result: {
          action,
          study_id,
          findings,
          email,
        },
      }
      await AxiosInstance.post(getAPIEndPoints.reports.action(), {
        data: JSON.stringify(payload),
        headers: {
          'Content-Type': 'application/json',
          Authorization: AUTH_TOKEN,
        },
      })
    } catch (e) {
      return rejectWithValue({})
    }
  }
)

const { reducer } = createSlice({
  name: 'report',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder

      // ======================================================================================

      .addCase(requestGetStudy.pending, state => {
        state.inFlights.requestGetStudyInFlight.status = InFlightStatuses.PENDING
      })
      .addCase(requestGetStudy.fulfilled, (state, action) => {
        state.inFlights.requestGetStudyInFlight.status = InFlightStatuses.SUCCESS
        const check_mod = action.payload[0].mod_study === OSTEOCHECK_MOD_STUDY
        check_mod ? (state.reports.osteocheck = true) : (state.reports.osteocheck = false)
        state.reports.studyData = action.payload[0]
      })
      .addCase(requestGetStudy.rejected, state => {
        state.inFlights.requestGetStudyInFlight.status = InFlightStatuses.ERROR
      })

      // ======================================================================================

      .addCase(requestGetReportTemplate.pending, state => {
        state.inFlights.requestGetReportTemplateInFlight.status = InFlightStatuses.PENDING
      })
      .addCase(requestGetReportTemplate.fulfilled, (state, action) => {
        state.inFlights.requestGetReportTemplateInFlight.status = InFlightStatuses.SUCCESS
        state.reports.FormData = action.payload
      })
      .addCase(requestGetReportTemplate.rejected, state => {
        state.inFlights.requestGetReportTemplateInFlight.status = InFlightStatuses.ERROR
      })

      // ======================================================================================

      .addCase(requestSendBackToHilPool.pending, state => {
        state.inFlights.requestSendBackToHilPoolInFlight.status = InFlightStatuses.PENDING
      })
      .addCase(requestSendBackToHilPool.fulfilled, state => {
        state.inFlights.requestSendBackToHilPoolInFlight.status = InFlightStatuses.SUCCESS
      })
      .addCase(requestSendBackToHilPool.rejected, state => {
        state.inFlights.requestSendBackToHilPoolInFlight.status = InFlightStatuses.ERROR
      })

      // ======================================================================================

      .addCase(requestGetDicomUrlToken.pending, state => {
        state.inFlights.requestGetDicomUrlTokenInFlight.status = InFlightStatuses.PENDING
      })
      .addCase(requestGetDicomUrlToken.fulfilled, (state, action) => {
        state.reports.urlToken = action.payload
        state.inFlights.requestGetDicomUrlTokenInFlight.status = InFlightStatuses.SUCCESS
      })
      .addCase(requestGetDicomUrlToken.rejected, state => {
        state.inFlights.requestGetDicomUrlTokenInFlight.status = InFlightStatuses.ERROR
      })

      // ======================================================================================

      .addCase(requestTakeAction.pending, state => {
        state.inFlights.requestTakeActionInFlight.status = InFlightStatuses.PENDING
      })
      .addCase(requestTakeAction.fulfilled, state => {
        state.inFlights.requestTakeActionInFlight.status = InFlightStatuses.SUCCESS
      })
      .addCase(requestTakeAction.rejected, state => {
        state.inFlights.requestTakeActionInFlight.status = InFlightStatuses.ERROR
      })


  },
})

export default reducer
