import { Col, Flex, Modal, Typography } from 'antd'
import { FullScreen, FullScreenHandle, useFullScreenHandle } from 'react-full-screen'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { TextColors, XRAY_BIONIC } from '../utils/constants'
import StudySelect from '../components/StudySelect'
import styled from 'styled-components'
import { Button as AntButton } from 'antd'
import { theme } from '../components/shared/theme'
import { RuleType, requestHilEligibleStudies, requestUpdateModStudy } from '../redux/cases/caseSlice'
import { StudyType, requestGetStudy } from '../redux/reports/reportSlice'
import { useAppDispatch, useAppSelector } from '../redux/utils/hooks'
import OverlayModal from '../components/shared/OverlayModal'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { getRuleName, isInFlightPending } from '../utils/helper'
import { InFlightNames } from '../utils/types'

type xrayStudyType = {
  studyData: StudyType
  viewer: any
  updateModStudyId?: (id: number) => void
}

const XrayStudy = (props: xrayStudyType) => {
  const { studyData, viewer, updateModStudyId } = props
  const { Text } = Typography

  const screen1 = useFullScreenHandle()
  const studySelectFunctionRef = useRef<any>()
  const [ruleUpdateConfirmationModal, setRuleUpdateConfirmationModal] = useState<boolean>(false)
  const dispatch = useAppDispatch()

  const { id } = useAppSelector(state => state.auth.user)
  const { inFlights } = useAppSelector(state => state.report)
  const { inFlights: caseInflights } = useAppSelector(state => state.cases)

  const reportChange = useCallback(
    (state: any, handle: FullScreenHandle) => {
      if (handle === screen1) {
        console.log('Screen 1 went to', state, handle)
      }
    },
    [screen1]
  )

  const handleToggleRuleUpdateConfirmationModal = () => {
    setRuleUpdateConfirmationModal(prev => !prev)
  }

  const saveRuleChange = async () => {
    if (!studySelectFunctionRef.current.RulesValidator()) {
      return
    }
    handleToggleRuleUpdateConfirmationModal()
  }

  const handleUpdateRule = async () => {
    const rules: RuleType[] = studySelectFunctionRef.current.getRules()
    await dispatch(requestUpdateModStudy({ study_id: studyData?.study_id, rules, by_user_id: id }))
    await dispatch(requestGetStudy({ id: studyData?.study_id, userId: id }))
    handleToggleRuleUpdateConfirmationModal?.()
  }

  const renderFooterButtons = () => {
    return (
      <>
        <CloseModalButton
          onClick={handleToggleRuleUpdateConfirmationModal}
          size='large'
        >
          Cancel
        </CloseModalButton>
        <SendToPoolButton
          onClick={handleUpdateRule}
          size='large'
          loading={
            isInFlightPending(inFlights, InFlightNames.requestGetStudyInFlight) ||
            isInFlightPending(caseInflights, InFlightNames.requestUpdateModStudyInFlight)
          }
        >
          Confirm
        </SendToPoolButton>
      </>
    )
  }

  useEffect(() => {
    dispatch(requestHilEligibleStudies({ study_id: studyData?.study_id }))
  }, [])

  const ruleIdsToExclude = useMemo(() => {
    return [XRAY_BIONIC]
  }, [])

  const isBlockUserModalOpen = useMemo(() => {
    return ['NON_REPORTABLE', 'REPORTABLE', 'IN_POOL'].includes(studyData?.status)
  }, [studyData])

  const getBlockUserModalText = () => {
    if (studyData?.status === 'NON_REPORTABLE') {
      return 'This case no longer exists for review. This case is skipped'
    } else if (studyData?.status === 'IN_POOL') {
      return 'This case is not assigned to user'
    }
    return 'This case has already been reported'
  }

  return (
    <Flex
      align='stretch'
      style={{ height: '100vh' }}
    >
      <OverlayModal
        isModalOpen={isBlockUserModalOpen}
        content={
          <Typography.Title
            level={5}
            style={{ margin: 0, padding: '1rem', textAlign: 'center' }}
          >
            <Flex
              gap='small'
              justify='center'
            >
              <ExclamationCircleOutlined style={{ color: theme.colors.warning }} />
              {getBlockUserModalText()}
            </Flex>
          </Typography.Title>
        }
      />
      <Col
        span={16}
        style={{ height: '100%' }}
      >
        <div
          className='dicom-viewer'
          style={{ height: '100%' }}
        >
          <div
            className='zoom-container'
            style={{ margin: 0, padding: 0 }}
          ></div>
          <div
            className='dicom-wrapper'
            style={{ height: '100%' }}
          >
            <FullScreen
              handle={screen1}
              onChange={reportChange}
              className='fullscreen-container'
            >
              <div
                className='full-screenable-node'
                style={{ height: '100%', position: 'relative' }}
              >
                <iframe
                  title='Dicom-Viewer'
                  src={viewer}
                  style={{ width: '100%', height: '100%' }}
                  frameBorder='0'
                ></iframe>
              </div>
            </FullScreen>
          </div>
        </div>
      </Col>
      <Col span={8}>
        <StudySelect
          ruleIdsToExclude={ruleIdsToExclude}
          ref={studySelectFunctionRef}
          formName={['user', 'Studies']}
          defaultRule={studyData?.rules?.[0]}
          isMultiSelect={true}
        />
        <FullWidthButton
          type='default'
          size='large'
          onClick={saveRuleChange}
        >
          Save
        </FullWidthButton>
      </Col>
      <Modal
        width={500}
        centered
        closable={false}
        title={<Text> {'Please confirm'}</Text>}
        open={ruleUpdateConfirmationModal}
        onCancel={() => {}}
        footer={renderFooterButtons()}
        destroyOnClose
      >
        <ModalContent>
          <Text>{`Are you sure to update the study for this case .Please confirm`}</Text>
          <Text strong>
            {studySelectFunctionRef?.current
              ?.getRules()
              ?.map((rule: RuleType) => getRuleName(rule))
              ?.join(',')}
          </Text>
        </ModalContent>
      </Modal>
    </Flex>
  )
}

export default XrayStudy

const FullWidthButton = styled(AntButton)`
  color: ${TextColors.appBackground};
  font-weight: bold;
  font-size: 1rem;
  width: 96%;
  background-color: ${theme.colors.black};
  margin: 0.5rem 2%;
  margin-bottom: 2rem;
`

const SendToPoolButton = styled(AntButton)`
  color: ${TextColors.appBackground};
  font-weight: bold;
  font-size: 1rem;
  background-color: ${theme.colors.black};
  margin: 0.5rem 2%;
  margin-bottom: 2rem;
`

const CloseModalButton = styled(AntButton)`
  color: ${theme.colors.black};
  font-weight: bold;
  font-size: 1rem;
  background-color: ${TextColors.appBackground};
  margin: 0.5rem 2%;
  margin-bottom: 2rem;
`
const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 1rem 3rem;
`
