export type InFlightsType = {
  [InFlightName: string]: { status: InFlightStatuses }
}

export enum InFlightStatuses {
  INITIAL = 'INITIAL',
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

export enum UserStatusTypes {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  AWAY = 'AWAY',
}

export enum UserTypes {
  RADIOLOGIST = 'RADIOLOGIST',
  BOT = 'BOT',
  VENDOR = 'VENDOR',
  MODEL = 'MODEL',
}

export enum InFlightNames {
  // auth

  requestLoginInFlight = 'requestLoginInFlight',
  requestUserInfoInFlight = 'requestUserInfoInFlight',
  requestUserStatusInFlight = 'requestUserStatusInFlight',
  requestUpdateUserStatusInFlight = 'requestUpdateUserStatusInFlight',

  // cases

  requestCasesInPoolCountInFlight = 'requestCasesInPoolCountInFlight',
  requestNewCaseInFlight = 'requestNewCaseInFlight',
  requestCaseBasedOnDateInFlight = 'requestCaseBasedOnDateInFlight',
  requestSkipReasonInFlight = 'requestSkipReasonsInFlight',
  requestModStudiesInFlight = 'requestModStudiesInFlight',
  requestHilEligibleStudiesInFlight = 'requestHilEligibleStudiesInFlight',
  requestEligibleOnlineUsersInFlight = 'requestEligibleOnlineUsersInFlight',
  requestUpdateModStudyInFlight='requestUpdateModStudyInFlight',

  // reports
  requestGetStudyInFlight = 'requestGetStudyInFlight',
  requestGetReportTemplateInFlight = 'requestGetReportTemplateInFlight',
  requestGetDicomUrlTokenInFlight = 'requestGetDicomUrlTokenInFlight',
  requestTakeActionInFlight = 'requestTakeActionInFlight',
  requestSendBackToHilPoolInFlight = 'requestSendBackToHilPoolInFlight',

  // settings
  requestUpdateSettingsInFlight = 'requestUpdateSettingsInFlight',
  requestGetSettingsInFlight = 'requestGetSettingsInFlight',
}
