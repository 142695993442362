import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { Actions } from '../utils/actionTypes'
import { getAPIEndPoints } from '../../utils/api'
import AxiosInstance from '../AxiosInstance'
import { InFlightNames, InFlightStatuses } from '../../utils/types'
import {
  OnReceiveModStudyType,
  OnRequestEligibleOnlineUsers,
  OnRequestUpdateModStudy,
  RequestCaseByDatePayloadType,
  RequestGetCasesInPoolCountPayloadType,
  RequestGetNewCasePayloadType,
  RequestSkipReasonPayloadType,
  onReceiveGetCasesInPoolCountPayloadType,
  onReceiveGetNewCasePayloadType,
  onReceiveSkipReasonsPayloadType,
  ruleUpdateResponseAssignmentResponse,
} from './casePayloadTypes'
import { message } from 'antd'
import { AUTH_TOKEN } from 'src/utils/constants'
import { CORE_PLATFORM_URL, channel } from '../../../src/utils/constants'

export enum StudyStatuses {
  RECEIVED = 'RECEIVED',
  ASSIGNED = 'ASSIGNED',
  OPENED = 'OPENED',
  IN_POOL = 'IN_POOL',
  WAIT = 'WAIT',
  REPORTABLE = 'REPORTABLE',
  NON_REPORTABLE = 'NON_REPORTABLE',
  DELETED = 'DELETED',
  MODEL_WAIT = 'MODEL_WAIT',
}
export type CaseType = {
  order_id: string
  study_id: number
  patient_name: string
  report_header: string
  created_at: string
  status: StudyStatuses
  mod_study: number
  study_iuid: string
  history: string
  ct_ratio: string | null
}

export enum ReasonTypes {
  DEFAULT = 'DEFAULT',
  SKIP = 'SKIP',
}

export enum MandatoryItemType {
  GENDER = 'Gender',
  SIDE = 'Side',
  VIEW = 'View',
}

export type RuleType = {
  id: number
  list: string[]
  children: {
    list: string[]
    mod_study: number
  }[]
  mandatory: {
    type: MandatoryItemType
    values: string[]
    mod_study: number
  }[]
  additional: {
    type: string
    list: string[]
    mod_study: number
  }[]
  keywords: string[]
  hidden?: boolean
  mod_study: string
  format_id: string
  uid: string
  rules?: any
  client_study_name: string
}

export type ReasonItemType = {
  reason: string
  type: ReasonTypes
  updated_at: string
  id: number
  created_at: string
}

export interface CaseState {
  case_details: CaseType
  pool_count: number
  skipReasons: ReasonItemType[]
  modStudiesList: RuleType[]
  hilEligibleStudies: { id: number }[]
  modStudyEligibleUsers: { id: number }[]
  inFlights: {
    [InFlightNames.requestCasesInPoolCountInFlight]: { status: InFlightStatuses }
    [InFlightNames.requestNewCaseInFlight]: { status: InFlightStatuses }
    [InFlightNames.requestCaseBasedOnDateInFlight]: { status: InFlightStatuses }
    [InFlightNames.requestSkipReasonInFlight]: { status: InFlightStatuses }
    [InFlightNames.requestModStudiesInFlight]: { status: InFlightStatuses }
    [InFlightNames.requestHilEligibleStudiesInFlight]: { status: InFlightStatuses }
    [InFlightNames.requestEligibleOnlineUsersInFlight]: { status: InFlightStatuses }
    [InFlightNames.requestUpdateModStudyInFlight]: { status: InFlightStatuses }
  }
}

const initialState: CaseState = {
  pool_count: 0,
  case_details: {} as CaseType,
  skipReasons: [],
  modStudiesList: [],
  hilEligibleStudies: [],
  modStudyEligibleUsers: [],
  inFlights: {
    [InFlightNames.requestCasesInPoolCountInFlight]: { status: InFlightStatuses.INITIAL },
    [InFlightNames.requestNewCaseInFlight]: { status: InFlightStatuses.INITIAL },
    [InFlightNames.requestCaseBasedOnDateInFlight]: { status: InFlightStatuses.INITIAL },
    [InFlightNames.requestSkipReasonInFlight]: { status: InFlightStatuses.INITIAL },
    [InFlightNames.requestModStudiesInFlight]: { status: InFlightStatuses.INITIAL },
    [InFlightNames.requestHilEligibleStudiesInFlight]: { status: InFlightStatuses.INITIAL },
    [InFlightNames.requestEligibleOnlineUsersInFlight]: { status: InFlightStatuses.INITIAL },
    [InFlightNames.requestUpdateModStudyInFlight]: { status: InFlightStatuses.INITIAL },
  },
}

export const requestCasesInPoolCount = createAsyncThunk<
  onReceiveGetCasesInPoolCountPayloadType,
  RequestGetCasesInPoolCountPayloadType
>(Actions.requestCasesInPoolCount, async (data, { rejectWithValue }) => {
  const { email } = data
  try {
    const res = await AxiosInstance.get(getAPIEndPoints.cases.pool_count(email), {
      headers: {
        Authorization: AUTH_TOKEN,
      },
    })
    return { pool_count: res.data.pool_count }
  } catch (error) {
    message.error(`Failed to Get count: ${error.message}`)
    return rejectWithValue({})
  }
})

export const requestNewCase = createAsyncThunk<onReceiveGetNewCasePayloadType, RequestGetNewCasePayloadType>(
  Actions.requestNewCase,
  async (data, { rejectWithValue }) => {
    const { email } = data
    try {
      const Case = await AxiosInstance.get(getAPIEndPoints.cases.new_case(email), {
        headers: {
          Authorization: AUTH_TOKEN,
        },
      })
      if (Case.data.id === null) {
        message.warning('No Case Found')
      } else {
        window.open(`/Cases/${Case.data.id}`, '_blank')
      }
      return Case.data
    } catch (error) {
      message.error(`Failed to Get new case: ${error.message}`)
      return rejectWithValue({})
    }
  }
)

export const requestCaseBasedOnDate = createAsyncThunk<any, RequestCaseByDatePayloadType>(
  Actions.requestCaseBasedOnDate,
  async (data, { rejectWithValue }) => {
    const { formattedDate, email } = data
    try {
      const response = await AxiosInstance.get(getAPIEndPoints.cases.get_case_by_date(formattedDate, email), {
        headers: {
          Authorization: AUTH_TOKEN,
        },
      })
      if (response.data.data.cases.length > 0) {
        const sortedData = response.data.data.cases.sort(
          (a: { status: string; created_at: any }, b: { status: string; created_at: any }) => {
            if (a.status === 'ASSIGNED' && b.status === 'ASSIGNED') {
              return new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
            } else if (a.status === 'ASSIGNED') {
              return -1
            } else if (b.status === 'ASSIGNED') {
              return 1
            } else if (
              (a.status === 'REPORTABLE' || a.status === 'NON_REPORTABLE') &&
              (b.status === 'REPORTABLE' || b.status === 'NON_REPORTABLE')
            ) {
              return new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
            } else {
              return 0
            }
          }
        )
        return sortedData
      } else {
        message.warning('No cases Found')
      }
    } catch (error) {
      return rejectWithValue({})
    }
  }
)

export const requestSkipReasons = createAsyncThunk<onReceiveSkipReasonsPayloadType, RequestSkipReasonPayloadType>(
  Actions.requestSkipReasons,
  async (data, { rejectWithValue }) => {
    const { reasonType } = data
    try {
      const Reasons = await AxiosInstance.get(getAPIEndPoints.cases.get_skip_reasons(reasonType), {
        headers: {
          Authorization: AUTH_TOKEN,
        },
      })
      if (Reasons?.data?.length === 0) {
        message.warning('No Skip Reasons Found')
      }
      return Reasons.data
    } catch (error) {
      message.error(`Failed to Get skip reasons: ${error.message}`)
      return rejectWithValue([])
    }
  }
)

export const requestModStudies = createAsyncThunk<OnReceiveModStudyType>(
  Actions.requestModStudies,
  async (_, { rejectWithValue }) => {
    try {
      const response = await AxiosInstance.get(getAPIEndPoints.cases.modStudies(), {
        baseURL: CORE_PLATFORM_URL,
        headers: {
          Authorization: AUTH_TOKEN,
        },
      })
      return response.data
    } catch (e) {
      console.log('failed to get mod studies', e)
      return rejectWithValue([])
    }
  }
)

export const requestUpdateModStudy = createAsyncThunk<void, OnRequestUpdateModStudy>(
  Actions.requestUpdateModStudy,
  async data => {
    //Do not add try catch here --  for toasts
    const response = await AxiosInstance.patch(getAPIEndPoints.cases.updateModStudy(), {
      headers: {
        Authorization: AUTH_TOKEN,
      },
      data,
    })
    if (response?.data?.[0]?.assignment != ruleUpdateResponseAssignmentResponse.NOT_CHANGED) {
      channel.postMessage('refetch-pool-data')
      window.close()
    }
  }
)

export const requestHilEligibleStudies = createAsyncThunk<{ id: number }[], { study_id?: number }>(
  Actions.requestHilEligibleStudies,
  async (data, { rejectWithValue }) => {
    const { study_id } = data
    try {
      const response = await AxiosInstance.get(getAPIEndPoints.cases.getHilStudies(study_id))
      return response.data
    } catch (e) {
      console.log('failed to get mod studies', e)
      return rejectWithValue([])
    }
  }
)

export const requestEligibleOnlineUsers = createAsyncThunk<{ id: number }[], OnRequestEligibleOnlineUsers>(
  Actions.requestEligibleOnlineUsers,
  async (data, { rejectWithValue }) => {
    const { mod_study, type_id } = data
    try {
      const response = await AxiosInstance.get(getAPIEndPoints.cases.getEligibleOnlineUsers(mod_study, type_id), {
        headers: {
          Authorization: AUTH_TOKEN,
        },
      })
      return response.data
    } catch (e) {
      console.log('failed to get mod studies', e)
      return rejectWithValue([])
    }
  }
)

const { reducer } = createSlice({
  name: 'cases',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      //

      .addCase(requestCasesInPoolCount.pending, state => {
        state.inFlights.requestCasesInPoolCountInFlight.status = InFlightStatuses.PENDING
      })
      .addCase(requestCasesInPoolCount.fulfilled, (state, action) => {
        state.pool_count = action.payload.pool_count
        state.inFlights.requestCasesInPoolCountInFlight.status = InFlightStatuses.SUCCESS
      })
      .addCase(requestCasesInPoolCount.rejected, state => {
        state.inFlights.requestCasesInPoolCountInFlight.status = InFlightStatuses.ERROR
      })

      .addCase(requestNewCase.pending, state => {
        state.inFlights.requestNewCaseInFlight.status = InFlightStatuses.PENDING
      })
      .addCase(requestNewCase.fulfilled, (state, action) => {
        state.inFlights.requestNewCaseInFlight.status = InFlightStatuses.SUCCESS
      })
      .addCase(requestNewCase.rejected, state => {
        state.inFlights.requestNewCaseInFlight.status = InFlightStatuses.ERROR
      })

      .addCase(requestCaseBasedOnDate.pending, state => {
        state.inFlights.requestCaseBasedOnDateInFlight.status = InFlightStatuses.PENDING
      })
      .addCase(requestCaseBasedOnDate.fulfilled, (state, action) => {
        state.inFlights.requestCaseBasedOnDateInFlight.status = InFlightStatuses.SUCCESS
      })
      .addCase(requestCaseBasedOnDate.rejected, state => {
        state.inFlights.requestCaseBasedOnDateInFlight.status = InFlightStatuses.ERROR
      })

      .addCase(requestSkipReasons.pending, state => {
        state.inFlights.requestSkipReasonsInFlight.status = InFlightStatuses.PENDING
      })
      .addCase(requestSkipReasons.fulfilled, (state, action) => {
        state.skipReasons = action.payload
        state.inFlights.requestSkipReasonsInFlight.status = InFlightStatuses.SUCCESS
      })
      .addCase(requestSkipReasons.rejected, state => {
        state.inFlights.requestSkipReasonsInFlight.status = InFlightStatuses.ERROR
      })
      //
      .addCase(requestModStudies.pending, state => {
        state.inFlights.requestModStudiesInFlight.status = InFlightStatuses.PENDING
      })
      .addCase(requestModStudies.fulfilled, (state, action) => {
        state.modStudiesList = action.payload
        state.inFlights.requestModStudiesInFlight.status = InFlightStatuses.SUCCESS
      })
      .addCase(requestModStudies.rejected, state => {
        state.modStudiesList = []
        state.inFlights.requestModStudiesInFlight.status = InFlightStatuses.ERROR
      })

      //
      .addCase(requestHilEligibleStudies.pending, state => {
        state.inFlights.requestHilEligibleStudiesInFlight.status = InFlightStatuses.PENDING
      })
      .addCase(requestHilEligibleStudies.fulfilled, (state, action) => {
        state.hilEligibleStudies = action.payload
        state.inFlights.requestHilEligibleStudiesInFlight.status = InFlightStatuses.SUCCESS
      })
      .addCase(requestHilEligibleStudies.rejected, state => {
        state.hilEligibleStudies = []
        state.inFlights.requestHilEligibleStudiesInFlight.status = InFlightStatuses.ERROR
      })
      //
      .addCase(requestEligibleOnlineUsers.pending, state => {
        state.inFlights.requestEligibleOnlineUsersInFlight.status = InFlightStatuses.PENDING
      })
      .addCase(requestEligibleOnlineUsers.fulfilled, (state, action) => {
        state.modStudyEligibleUsers = action.payload
        state.inFlights.requestEligibleOnlineUsersInFlight.status = InFlightStatuses.SUCCESS
      })
      .addCase(requestEligibleOnlineUsers.rejected, state => {
        state.modStudyEligibleUsers = []
        state.inFlights.requestEligibleOnlineUsersInFlight.status = InFlightStatuses.ERROR
      })
      //
      .addCase(requestUpdateModStudy.pending, state => {
        state.inFlights.requestUpdateModStudyInFlight.status = InFlightStatuses.PENDING
      })
      .addCase(requestUpdateModStudy.fulfilled, (state, action) => {
        state.inFlights.requestUpdateModStudyInFlight.status = InFlightStatuses.SUCCESS
      })
      .addCase(requestUpdateModStudy.rejected, state => {
        state.inFlights.requestUpdateModStudyInFlight.status = InFlightStatuses.ERROR
      })
  },
})

export default reducer
