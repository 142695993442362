import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { Actions } from '../utils/actionTypes'
import { getAPIEndPoints } from '../../utils/api'
import AxiosInstance from '../AxiosInstance'
import { InFlightNames, InFlightStatuses } from '../../utils/types'
import {
  onReceiveUpdateSettingsPayloadType,
  RequestSettingsPayloadType,
  onReceiveGetSettingsPayloadType,
} from './settingPayloadTypes'
import { message } from 'antd'
import { AUTH_TOKEN } from 'src/utils/constants'

export enum SettingsKey {
  IS_RAD_AUTO_ASSIGN_ENABLED = 'is_rad_auto_assign_enabled',
  SPECIAL_CASE_MAX_COUNT = 'special_case_max_count',
}
export type SettingsType = { [key in SettingsKey]: string }

export interface SettingsState {
  settings: SettingsType
  inFlights: {
    [InFlightNames.requestUpdateSettingsInFlight]: { status: InFlightStatuses }
    [InFlightNames.requestGetSettingsInFlight]: { status: InFlightStatuses }
  }
}

const initialState: SettingsState = {
  settings: { is_rad_auto_assign_enabled: 'false', special_case_max_count: '0' },
  inFlights: {
    [InFlightNames.requestUpdateSettingsInFlight]: { status: InFlightStatuses.INITIAL },
    [InFlightNames.requestGetSettingsInFlight]: { status: InFlightStatuses.INITIAL },
  },
}

export const requestUpdateSettings = createAsyncThunk<onReceiveUpdateSettingsPayloadType, RequestSettingsPayloadType>(
  Actions.requestUpdateSettings,
  async (data, { rejectWithValue }) => {
    try {
      await AxiosInstance.post(getAPIEndPoints.settings.insertOrUpdate(), {
        data,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': AUTH_TOKEN
        },
      })

      message.success('Updated setting successfully')
      return data
    } catch (error) {
      message.error(`Update setting failed: ${error.message}`)
      return rejectWithValue({})
    }
  }
)

export const requestGetSettings = createAsyncThunk<onReceiveGetSettingsPayloadType, void>(
  Actions.requestGetSettings,
  async (_, { rejectWithValue }) => {
    try {
      const response = await AxiosInstance.get(getAPIEndPoints.settings.get(), 
      { headers: {
        'Authorization': AUTH_TOKEN
      }})
      return response.data
    } catch (error) {
      message.error(`Get settings failed: ${error.message}`)
      return rejectWithValue({})
    }
  }
)

const { reducer } = createSlice({
  name: 'settings',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      //

      .addCase(requestUpdateSettings.pending, state => {
        state.inFlights.requestUpdateSettingsInFlight.status = InFlightStatuses.PENDING
      })
      .addCase(requestUpdateSettings.fulfilled, (state, action) => {
        const { key, value } = action.payload
        state.settings = { ...state.settings, [key]: value }
        state.inFlights.requestUpdateSettingsInFlight.status = InFlightStatuses.SUCCESS
      })
      .addCase(requestUpdateSettings.rejected, state => {
        state.inFlights.requestUpdateSettingsInFlight.status = InFlightStatuses.ERROR
      })

      //======================================================

      .addCase(requestGetSettings.pending, state => {
        state.inFlights.requestGetSettingsInFlight.status = InFlightStatuses.PENDING
      })
      .addCase(requestGetSettings.fulfilled, (state, action) => {
        state.settings = action.payload
        state.inFlights.requestGetSettingsInFlight.status = InFlightStatuses.SUCCESS
      })
      .addCase(requestGetSettings.rejected, state => {
        state.inFlights.requestGetSettingsInFlight.status = InFlightStatuses.ERROR
      })
  },
})

export default reducer
